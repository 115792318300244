var Cookie = require('./dom').Cookie;
require('./extend');

var Preferences = Object.extend(
    function Preferences_constructor() {
        this.v = {};
    }, {
        get: function(key, default_value) {
            return this.v[key] != void 0 ? this.v[key] : default_value
        },

        _set: function(key, value) {
            return this.v[key] = value;
        },

        set: function(key, value) {
            if (value == void 0) {
                this.del(key);
                return void 0;
            }
            return this._set(key, value);
        },

        del: function(key) {
            var old = this.v[key];
            delete this.v[key];
            return old;
        }
    }
);

var CookiePreferences = Preferences.extend(
    function CookiePref_constructor() {
        Preferences.call(this);
        for (var key in Cookie.list) {
            var m, value = Cookie.list[key];
            if (m = key.match(/^pref_(.*)$/))
                try {
                    this.v[m[1]] = JSON.parse(value);
                } catch(e) {
                    this.v[m[1]] = value;
                }
        }
    }, {
        _set: function(key, value) {
            Preferences.prototype._set.call(this, key, value);
            Cookie.set("pref_"+key, JSON.stringify(value), "10y");
            return value;
        },

        del: function(key) {
            var old = Preferences.prototype.del.call(this, key);
            Cookie.set("pref_"+key, void 0);
            return old;
        }
    }
);

var Prefs = new CookiePreferences();

(function(){ // Upgrade old cookie names to new preference names
    var upgrade = [
        'order',
        'cardset',
        'cardback'
    ];

    Cookie.set("moves", void 0);

    for (var ui in upgrade)
        if (Cookie.list[upgrade[ui]]) {
            Prefs.set(upgrade[ui], Cookie.list[upgrade[ui]]);
            Cookie.set(upgrade[ui], void 0);
        }
})();

module.exports = Prefs;
