// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.

class PlatformSpecific {
  constructor() {
    try { window.navigator }
    catch(e) {
        if (this.node = !!global.process) global.window = global;
        return; // Not a browser. Probably Node.
    }
    //debugger;
    this.ua = navigator.userAgent;
    this.op = !!(window.opera && document.getElementById);
    this.op6 = !!(this.op && !(this.body && this.body.innerHTML));
    if (this.op && !this.op6) document.onmousedown = function (e) {
        if (((e = e || window.event).target || e.srcElement).tagName == "IMAGE") return false; };
    if (navigator.appName == 'Microsoft Internet Explorer') { // http://msdn.microsoft.com/en-us/library/ms537509%28VS.85%29.aspx
        var match = this.ua.match(/MSIE ([0-9]{1,}[.0-9]{0,})/);
        this.ie = match ? parseFloat(match[1]) : 1;
    }
    this.iemac = !!(this.ie && this.ua.match(/mac/i));
    this.ie4 = !!(this.ie && !document.getElementById);
    this.n4 = !!(document.layers && typeof document.classes != "undefined");
    this.n6 = !!(typeof window.getComputedStyle != "undefined" && typeof document.createRange != "undefined");
    this.w3c = !!(!this.op && !this.ie && !this.n6 && document.getElementById);
    this.ce = !!(document.captureEvents && document.releaseEvents);
    this.px = (this.n4 || this.op6)? '' : 'px';
    this.tiv = this.w3c? 40 : 10;
    this.mac = window.navigator && window.navigator.platform && !!window.navigator.platform.toLowerCase().match(/mac/);
    this.ios = window.navigator && window.navigator.platform && !!window.navigator.platform.toLowerCase().match(/iphone|ipad|ipod/);

    if (this.safari = this.ua.match(/Version\/(\d+\.\d+)\.\d+ Safari/) || undefined)
        this.safari = this.safari[1];
    if (this.webkit = this.ua.match(/AppleWebKit\/(\d+)/i) || undefined /* match returns null which sucks for inequalities */)
        this.webkit = this.webkit[1];

    this.has_canvas = !!document.createElement('canvas').getContext;
    this.has_placeholder = 'placeholder' in document.createElement('input');
    this.has_css_transitions = (function() { // http://stackoverflow.com/questions/7264899/detect-css-transitions-using-javascript-and-without-modernizr
        var s = document.createElement('p').style;
        return 'transition'       in s ||
               'WebkitTransition' in s ||
               'MozTransition'    in s ||
               'msTransition'     in s ||
               'OTransition'      in s;
    })();

    var me = this;
    this.has_css_animations = (function() { // https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Using_CSS_animations/Detecting_CSS_animation_support
        if (me.safari < 6.0) return false; // The old safari thinks it can do animations, but they don't work.
        var s = document.createElement('p').style;
        return 'animationName'       in s ||
               'WebkitAnimationName' in s ||
               'MozAnimationName'    in s ||
               'OAnimationName'      in s ||
               'msAnimationName'     in s ||
               'KhtmlAnimationName'  in s;
    })();


    this.left_button   = this.ie < 9 ? 1 : 0; // DOM spec says 0. IE does 1. Apparently fixed in IE9 but only if you use attachEvent.
    this.middle_button = this.ie < 9 ? 4 : 1;
    this.right_button  = this.ie < 9 ? 2 : 2;

    this.key_name = { 38:"up", 40:"down", 37:"left", 39:"right",
                     33:"page-up", 34:"page-down", 35:"end", 36:"home", 45:"insert", 46:"delete",
                     112:"f1", 113:"f2", 114:"f3", 115:"f4", 116:"f5", 117:"f6",
                     118:"f7", 119:"f8", 120:"f9", 121:"f10", 122:"f11", 123:"f12",
                     13:"return", 14:"enter", 9 :"tab", 8 :"backspace", 27:"escape", 6:"delete"/*mac firefox*/,
                     // Explorer
                     18:""/*alt(will have A- added below)*/, 16:"S-"/*shift*/,
                     192:"`", 189:"-", 187:"=", 219:"[", 221:"]", 220:"\\", 186:";", 222:"'", 188:",", 190:".", 191:"/",
                     // Safari:
                     63232: "up", 63233:"down", 63234:"left", 63235:"right",
                     63236:"f1", 63237:"f2", 63238:"f3", 63239:"f4", 63240:"f5", 63241:"f6",
                     63242:"f7", 63243:"f8", 63244:"f9", 63245:"f10", 63246:"f11", 63247:"f12",
                     63276:"page-up", 63277:"page-down", 63275:"end", 63273:"home", 63272:"delete",
                     3:"enter" };
    // Explorer seems to pass Shift-1 instead of ! etc.
    this.explorer_shift_map = { // These shifts of the above sequence are so specific to *my* keyboard it's not even funny.
                     192:"~", 189:"_", 187:"+", 219:"{", 221:"}", 220:"|", 186:":", 222:"\"", 188:"<", 190:">", 191:"?"};
    var explorer_shift_map = {"1":"!", "2":"@", "3":"#", "4":"$", "5":"%", "6":"^", "7":"&", "8":"*", "9":"(", "0":")"};
    for (var s in explorer_shift_map)
        this.explorer_shift_map[s.charCodeAt(0)] = explorer_shift_map[s];

    this.dom_code = {
            "DOM_VK_CANCEL"        : "cancel",
            "DOM_VK_HELP"          : "help",
            "DOM_VK_BACK_SPACE"    : "backspace",
            "DOM_VK_TAB"           : "tab",
            "DOM_VK_CLEAR"         : "clear",
            "DOM_VK_RETURN"        : "return",
            "DOM_VK_ENTER"         : "enter",
            "DOM_VK_SHIFT"         : "shift",
            "DOM_VK_CONTROL"       : "control",
            "DOM_VK_ALT"           : "alt",
            "DOM_VK_PAUSE"         : "pause",
            "DOM_VK_CAPS_LOCK"     : "capslock",
            "DOM_VK_ESCAPE"        : "escape",
            "DOM_VK_SPACE"         : "space",
            "DOM_VK_PAGE_UP"       : "PAGE-up",
            "DOM_VK_PAGE_DOWN"     : "page-down",
            "DOM_VK_END"           : "end",
            "DOM_VK_HOME"          : "home",
            "DOM_VK_LEFT"          : "left",
            "DOM_VK_UP"            : "up",
            "DOM_VK_RIGHT"         : "right",
            "DOM_VK_DOWN"          : "down",
            "DOM_VK_PRINTSCREEN"   : "printscreen",
            "DOM_VK_INSERT"        : "insert",
            "DOM_VK_DELETE"        : "delete",
            "DOM_VK_0"             : "0",
            "DOM_VK_1"             : "1",
            "DOM_VK_2"             : "2",
            "DOM_VK_3"             : "3",
            "DOM_VK_4"             : "4",
            "DOM_VK_5"             : "5",
            "DOM_VK_6"             : "6",
            "DOM_VK_7"             : "7",
            "DOM_VK_8"             : "8",
            "DOM_VK_9"             : "9",
            "DOM_VK_SEMICOLON"     : ";",
            "DOM_VK_EQUALS"        : "=",
            "DOM_VK_A"             : "a",
            "DOM_VK_B"             : "b",
            "DOM_VK_C"             : "c",
            "DOM_VK_D"             : "d",
            "DOM_VK_E"             : "e",
            "DOM_VK_F"             : "f",
            "DOM_VK_G"             : "g",
            "DOM_VK_H"             : "h",
            "DOM_VK_I"             : "i",
            "DOM_VK_J"             : "j",
            "DOM_VK_K"             : "k",
            "DOM_VK_L"             : "l",
            "DOM_VK_M"             : "m",
            "DOM_VK_N"             : "n",
            "DOM_VK_O"             : "o",
            "DOM_VK_P"             : "p",
            "DOM_VK_Q"             : "q",
            "DOM_VK_R"             : "r",
            "DOM_VK_S"             : "s",
            "DOM_VK_T"             : "t",
            "DOM_VK_U"             : "u",
            "DOM_VK_V"             : "v",
            "DOM_VK_W"             : "w",
            "DOM_VK_X"             : "x",
            "DOM_VK_Y"             : "y",
            "DOM_VK_Z"             : "z",
            "DOM_VK_CONTEXT_MENU"  : "context-menu",
            "DOM_VK_NUMPAD0"       : "0",
            "DOM_VK_NUMPAD1"       : "1",
            "DOM_VK_NUMPAD2"       : "2",
            "DOM_VK_NUMPAD3"       : "3",
            "DOM_VK_NUMPAD4"       : "4",
            "DOM_VK_NUMPAD5"       : "5",
            "DOM_VK_NUMPAD6"       : "6",
            "DOM_VK_NUMPAD7"       : "7",
            "DOM_VK_NUMPAD8"       : "8",
            "DOM_VK_NUMPAD9"       : "9",
            "DOM_VK_MULTIPLY"      : "*",
            "DOM_VK_ADD"           : "+",
            "DOM_VK_SEPARATOR"     : "separator",
            "DOM_VK_SUBTRACT"      : "-",
            "DOM_VK_DECIMAL"       : ".",
            "DOM_VK_DIVIDE"        : "/",
            "DOM_VK_F1"            : "F1",
            "DOM_VK_F2"            : "f2",
            "DOM_VK_F3"            : "f3",
            "DOM_VK_F4"            : "f4",
            "DOM_VK_F5"            : "f5",
            "DOM_VK_F6"            : "f6",
            "DOM_VK_F7"            : "f7",
            "DOM_VK_F8"            : "f8",
            "DOM_VK_F9"            : "f9",
            "DOM_VK_F10"           : "f10",
            "DOM_VK_F11"           : "f11",
            "DOM_VK_F12"           : "f12",
            "DOM_VK_F13"           : "f13",
            "DOM_VK_F14"           : "f14",
            "DOM_VK_F15"           : "f15",
            "DOM_VK_F16"           : "f16",
            "DOM_VK_F17"           : "f17",
            "DOM_VK_F18"           : "f18",
            "DOM_VK_F19"           : "f19",
            "DOM_VK_F20"           : "f20",
            "DOM_VK_F21"           : "f21",
            "DOM_VK_F22"           : "f22",
            "DOM_VK_F23"           : "f23",
            "DOM_VK_F24"           : "f24",
            "DOM_VK_NUM_LOCK"      : "num-lock",
            "DOM_VK_SCROLL_LOCK"   : "scroll-lock",
            "DOM_VK_COMMA"         : ",",
            "DOM_VK_PERIOD"        : ".",
            "DOM_VK_SLASH"         : "/",
            "DOM_VK_BACK_QUOTE"    : "`",
            "DOM_VK_OPEN_BRACKET"  : "[",
            "DOM_VK_BACK_SLASH"    : "\\",
            "DOM_VK_CLOSE_BRACKET" : "]",
            "DOM_VK_QUOTE"         : "\"",
            "DOM_VK_META"          : "meta"
    };
    this.dom_key_code = function(event) {
        if (!this.key_code) {
            this.key_code = {};
            for (var k in this.dom_code) {
                this.key_code[event[k]]          = this.dom_code[k];
                //printf("%3d (%s) = %s\n", event[k], k, this.dom_code[k]);
            }
        }
        return this.key_code[event.keyCode];
    };
    if ("index" in "1,2".split(/,/)) {
        var broken_chrome_split = String.prototype.split;
        String.prototype.split = function () {
            var a = broken_chrome_split.apply(this,arguments);
            delete a.index;
            delete a.input;
            return a;
        }
    }
  }

  windowWidth()  { return document.documentElement.clientWidth;  }
  windowHeight() { return document.documentElement.clientHeight; }

  // Keypress/Keydown why one or the other?
  // keypress seems to cancel stuff nicely--You can capture Command-A and it won't let the browser do select all (mac firefox and safari).
  // keypress doesn't do arrows on Safari 3 and 4.
  // keydown does arrows, but doesn't do symbols well ($ comes back as shift 4). Also I can't get it to cancel the event.
  // So choose wisely.
  register_key_events(div, handler) {
    // List of handlers comes next
    var args = Array.prototype.slice.call(arguments,2);
    var handlers = !args.length ? { keydown:1 } : {};
    for (var a in args)
        handlers[args[a]] = 1;

    if (!div.addEventListener) { // Fake it for IE.
        div.addEventListener = function (type, listener, use_capture) {
            this.attachEvent('on' + type, listener)
        };
        div.removeEventListener = function (type, listener, use_capture) {
            this.detachEvent('on' + type, listener)
        };
    }

    var cancel = {}, down, up;
    down = function(e) {
        if (document.activeElement && div != document.activeElement) {
            var tag = document.activeElement.tagName.toLowerCase();
            if (tag == "input" || tag == "textarea" || tag == "select")
                return;
        }

        var event = new plat.key_event(e);
        if (handler(event) == false) {
            cancel[event.key] = true;
            if (e.preventDefault) // DOM
                e.preventDefault();
            return e.returnValue/*for IE*/ = false;
        }
    }
    if (handlers.keydown)
        div.addEventListener("keydown", down, true);
    if (handlers.keypress)
        div.addEventListener("keypress", down, true);
    div.addEventListener("keyup", up = function(e) {
        var event = new plat.key_event(e);
        if (cancel[event.key] == true) {
            if (handlers.keyup) handler(event);
            delete cancel[event.key];
            if (e.preventDefault) // DOM
                e.preventDefault();
            return e.returnValue/*for IE*/ = false;
        }
    }, false);
    return function () {
        if (handlers.keydown)
            div.removeEventListener("keydown", down, true);
        if (handlers.keypress)
            div.removeEventListener("keypress", down, true);
        div.removeEventListener("keyup", up, false);
    };
  }

  register_window_focus_events(handler) {
    if (this.ie) {
        var lib = require('./lib'); // Not at the top level for recursive reasons.
        // Determined through experimentation. "Real" focus in/out events have src=HTML|BODY and to=null.
        document.onfocusout = function (event) {
            if (!event) event = window.event;
            if ((event.srcElement == document.body || event.srcElement == document.documentElement) && event.toElement === null) handler(0);
        }.bind(this);
        document.onfocusin  = function (event) {
            if (!event) event = window.event;
            if ((event.srcElement == document.body || event.srcElement == document.documentElement) && event.toElement === null) handler(1);
        }.bind(this);
    } else {
        window.onblur       = function() { handler(0); };
        window.onfocus      = function() { handler(1); };
    }
  }

  add_animationend_listner(el, callback) {
    return require('./dom').doc.add_events_listener(el, ['animationend', 'webkitAnimationEnd', 'oanimationend'], callback);
  }

  PageCoords(el, stop) {
    // Stupid IE counts margins, padding, and borders multiple times in its offsetLeft/offsetTop numbers. To
    // get accurate offsets we have to use its nonstandard getBoundingClientRect() function.  Firefox has this
    // function too. But IE always gives you offsets (+2,+2) for some reason. But we can tell this because
    // they also offset the <body> element by (+2,+2), so we just subtract the body offset from our el's
    // offset. That also saves us from worrying about document.body.scrollTop/Left since stupid
    // getBoundingClientRect() returns coordinates relative to the window.
    if (stop == undefined && 'getBoundingClientRect' in el) {
        var b = document.body.getBoundingClientRect();
        var r = el.getBoundingClientRect();
        return { x: r.left - b.left,
                 y: r.top  - b.top };
    }
    var off = { "x":0, "y":0 };
    for (; el != null && el != stop; el = el.offsetParent) {
        off.x += el.offsetLeft;
        off.y += el.offsetTop;
    }
    return off;
  }
}

class Event {
  constructor(event, elem) {
    if (!event) event = window.event; // stupid windows
    this.type = event.type;
    if ("button" in event) {
        // This is pure craziness: IE7 is passing events to my event handler via the function parameters that
        // are different from window.event. In particular, the event.button is the correct standards version
        // that all the other browsers use (0=left, 1=middle, 2=right) instead of the old IE numbering system
        // (1=left, 2=right, 4=middle). So detect this and convert it to the old ones since we already set up
        // constants for them.
        if (plat.ie < 9 && event.button != window.event.button)
            this.button = [1,4,2][event.button];
        else
            this.button = event.button;
    }
    this.target = event.target || event.srcElement || null;
    // Replative to the page (standard)
    this.pageX = event.pageX;
    this.pageY = event.pageY;
    if (event.pageX === undefined) { // ie < 9
        // This || lets us support both strict and quirks mode.
        this.pageX = event.clientX + (document.documentElement.scrollLeft || document.body.scrollLeft) - (!plat.iemac)*1;
        this.pageY = event.clientY + (document.documentElement.scrollTop  || document.body.scrollTop)  - (!plat.iemac)*1;
    }
    if (elem) {
        // Relative to elem
        var parent = plat.PageCoords(elem);
        this.targetX = this.pageX - parent.x;
        this.targetY = this.pageY - parent.y;
    }
    this.event = event;
  }

  stop() {
    if ("stopPropagation" in this.event)
        this.event.stopPropagation();
    else if ("cancelBubble" in this.event)
        this.event.cancelBubble = true;
    else if ("preventDefault" in this.event)
        this.event.preventDefault();
    return this.event.returnValue = false;
  }
}

class KeyEvent {
  constructor(event) {
    var lib = require('./lib'), isgraph=lib.isgraph, isupper=lib.isupper, tolower=lib.tolower, chr=lib.chr; // Not at the top level for recursive reasons.
    if (!event) event = window.event; // stupid windows
    var charCode = event.type == "keypress" ? this.charCode : undefined;
    this.keyCode = event.keyCode;
    this.charCode = this.keyCode ? undefined : charCode;
    this.ctrlKey = event.ctrlKey;
    this.altKey = event.altKey;
    this.metaKey = event.metaKey;
    this.shiftKey = event.shiftKey;
    this.type = event.type;

    var keyCode = this.keyCode;

    if (event.keyIdentifier) { // DOM 3 (Safari 4, for starters)
        var m;
        if      (event.keyIdentifier.toLowerCase() == 'u+0020')   this.key = "space";
        else if (event.keyIdentifier.toLowerCase() == 'u+0009')   this.key = "tab";
        else if (event.keyIdentifier.toLowerCase() == 'u+0008')   this.key = "backspace";
        else if (event.keyIdentifier.toLowerCase() == 'u+001b')   this.key = "escape";
        else if (event.keyIdentifier.toLowerCase() == 'enter')    this.key = "return";
        else if (m = event.keyIdentifier.match(/^U\+([\da-f]{4})$/i))
                                                 event.shiftKey ? this.key = chr(parseInt("0x"+m[1])).toUpperCase()
                                                                : this.key = chr(parseInt("0x"+m[1])).toLowerCase();
        else                                                      this.key = event.keyIdentifier.toLowerCase();
    }
    else if (isgraph(charCode))       this.key = chr(charCode);
    else if (charCode < 32 &&
             event.ctrlKey)           this.key = chr(charCode+96); // Safari gives ascii control chars
    else if (charCode == 32)          this.key = "space";
    else if (this.shiftKey && plat.explorer_shift_map[this.keyCode]) this.key = plat.explorer_shift_map[this.keyCode];
    else if (plat.dom_key_code(event) != undefined) this.key = plat.dom_key_code(event);
    else if (plat.key_name[keyCode] != undefined)  this.key = plat.key_name[keyCode];
    else {
        if (isupper(keyCode) && !this.shiftKey) // Stupid explorer!
            keyCode = tolower(keyCode);
        if (isgraph(keyCode))         this.key = chr(keyCode); // IE puts things in the keycode
        else if (keyCode == 32)       this.key = "space"; // Explorer
        else                              this.key = "\\"+(keyCode || charCode);
    }
    if (this.shiftKey)this.key = this.key.length == 1 ? this.key.toUpperCase() : "S-"+this.key;
    if (this.altKey)  this.key = "A-"+this.key;
    if (this.metaKey) this.key = "M-"+this.key;
    if (this.ctrlKey) this.key = "C-"+this.key;

//    printf("key: %s, keyCode: %d, charCode: %d\n",this.key,event.keyCode, event.charCode);
  }
}

let plat = new PlatformSpecific();
plat.event = Event;
plat.key_event = KeyEvent;

module.exports = plat;
