// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.

var plat = require('./platform');
var dom = require('./dom'), doc=dom.doc, px=dom.px;
var lib = require('./lib'), nbsp=lib.nbsp, merge=lib.merge;

//plat.has_css_animations = false;

function Alert(dom_el, options) {
    if (options == undefined) options = {};
    var titlebar;
    var goaway = !options.goaway ? {} :
        doc(["a", { className: "goaway "+(plat.mac || plat.ios ? "mac" : "pc"), href: "", onclick: function() { this.close(); return false; }.bind(this) },
                  doc.img("alert/close-box.png").set_class("goaway")]);

    var div = doc(["div", {className: "alert-centerer",
                           style: {
                             position: "fixed",
                             transform: "translate(-50%, -50%)",
                             left: "50%",
                             top: "50%",
                          }},
                  ["div", {className: "alertbox" + (plat.has_css_animations ? " animated bounceIn" : "")
                           + (options.className ? " "+options.className : ""),
                          },
                   titlebar = doc(["div", { className: "titlebar" },
                                   goaway,
                                   ["p", { className: "title" }, options.title || nbsp]]),

                   ["div", { className: "alert" },
                    dom_el]]]);

    titlebar.onmousedown = titlebar.ontouchstart = function(event) {
        var e = new plat.event(event, titlebar);
        if (event && event.target.className == "goaway") return; // Hack. Touch events seem to supercede "clicks" on ios so we have to manually give way if we clicked the close box.
        var drag = { cx:e.pageX, cy:e.pageY, ox:div.offsetLeft, oy:div.offsetTop,
                     old_move: document.onmousemove, old_up:   document.onmouseup,
                     old_tmove: document.ontouchmove, old_tend: document.ontouchend };
        document.onmousemove = document.ontouchmove = function(event) {
            var e = new plat.event(event, titlebar);
            div.style.left = px(drag.ox + e.pageX - drag.cx);
            div.style.top  = px(drag.oy + e.pageY - drag.cy);
            return false;
        }
        document.onmouseup = document.ontouchend = function(event) {
            var e = new plat.event(event, titlebar);
            document.onmousemove = drag.old_move;
            document.onmouseup   = drag.old_up;
            document.ontouchmove = drag.old_tmove;
            document.ontouchend  = drag.old_tend;
            return false;
        }
        return false;
    };

    this.unregister_escape = plat.register_key_events(document, function(e) {
        if (e.key == "escape") {
            this.close();
            if (options.cancel)
                options.cancel();
            return false;
        }
    }.bind(this),"keydown");

    document.body.appendChild(div);

    this.closers = [];
    this.div = div;
}

Alert.prototype.close = function() {
    if (this.unregister_escape)
        this.unregister_escape();

    var actually_close = function() {
        for (var c in this.closers)
            this.closers[c]();
        try { document.body.removeChild(this.div); }
        catch(e) { /* Could be closed already. We don't care. */ }
    }.bind(this);
    if (plat.has_css_animations) {
        plat.add_animationend_listner(this.div, actually_close);
        this.div.classList.remove("bounceIn");
        this.div.classList.add("animated", "fadeOutDown");
    } else
        actually_close();
};

Alert.prototype.on_close = function(callback) {
    this.closers.push(callback);
};

function new_text_alert(message, options) {
    options = options || {};
    var lines=message.split(/\n/);
    var div = document.createElement("div");
    var p;
    for (var l in lines)
        div.appendChild(doc(["p", { align:"center" }, lines[l]]));
    var close;
    div.appendChild(p = doc(["p", { align:"center" },
                                  close = doc(["a", {href:""} , "Click to close"])]));
    var alert = new Alert(div, merge(options, {goaway:true}));
    p.style.fontSize = "x-small";
    close.onclick = function() {
        alert.close();
        return false;
    };
    return alert;
}

Alert.new_text_alert = new_text_alert;
module.exports = Alert;
