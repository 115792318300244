// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.

var plat = require('./platform');
var json = require('./json');
var prnt = require('./print'), say=prnt.say;
var lib  = require('./lib'), merge=lib.merge, map=lib.map, filter=lib.filter;

function makeurlparams(/*params, params, ...*/)
{
    return filter(function(x) { return x != void 0 },
                  map(function(v,k) {
                          if (v == void 0 || k == void 0) return void 0;
                          return encodeURIComponent(k) + '=' + encodeURIComponent(v);
                      },
                      merge.apply(void 0, arguments)))
        .join('&');
}

function makeurl() // makeurl(components..., params...)
{
    var url = [], params = [], protocol='';
    for (var i=0; i<arguments.length; i++) {
        var component = arguments[i], param;
        if (typeof(component) == "object")
            params.push(component);
        else if (component != undefined)
            if (protocol == '' && !url.length && component.match(/:$/))
                protocol = component;
            else
                url.push(encodeURIComponent(component).replace(/%40/g,'@'));
    }
    return (!protocol && url.length == 0 ? '' : protocol + '/' + url.join('/')) + (params.length == 0 ? '' : '?' + makeurlparams.apply(null, params));
}

function loadContent(url, post, f, method, binary, sync) {
    var req;
    if (window.XMLHttpRequest)        // branch for native XMLHttpRequest object
        req = new XMLHttpRequest();
    else if (window.ActiveXObject)    // branch for IE/Windows ActiveX version
        req = new window.ActiveXObject("Microsoft.XMLHTTP");

    if (req) {
        req.onreadystatechange = function () {
            if (f && req.readyState == 4) {
                try { // we access the req function here to centralize the try/catch
                    var data = req[method || "responseText"], status = req.status, statusText = req.statusText, error;
                } catch (err) {
                    say({"request error: ": err, "data: ": data, "status: ": status, statusText: statusText}); 
                    data = undefined; error = String(err||"unknown request error");
                }
                if (status != 200)
                    error = statusText || req.getResponseHeader("X-Error") || "unknown status text ["+status+"]";

                if (f)
                    if (plat.node) // no special error handling in node, just let it throw.
                        f(data, error, status);
                    else {
                        var debug = require('./debug'); // debug requires ajax so can't do this globally.
                        debug.try_report(function() { f(data, error, status) },
                                         "loadContent callback",
                                         { url: url, post:post, method:method, binary:binary, sync:sync, data:data, error:error, status:status });
                    }
            }
        };
        try {
            req.open(post === void 0 ? "HEAD" :
                     post            ? "POST" :
                                       "GET", url, !sync); // can throw
            req.setRequestHeader('X-JS-Version', window.js_version || "unknown");
            if (post) req.setRequestHeader('Content-Type', post.mimetype);
            if (binary && req.overrideMimeType)
                req.overrideMimeType('text/plain; charset=x-user-defined'); // Marcus Granado 2006 [http://mgran.blogspot.com]
            req.send(post ? post.data : null); // can throw
        } catch(e) {
            if (f)
                f(undefined, 'description' in e ? e.description : ""+e, -1);
        }
    } else {
        alert('Your browser can\'t handle this script');
    }
}
function loadXML(url, f, sync) {
    return loadContent(url, null, f, 'responseXML', false, sync);
}
var responseBodyToArray_vb = '\n'
+'Function responseBodyToArray(body)\n'
+'    Dim array(), i\n'
+'    ReDim array(LenB(body)-1)\n'
+'    For i = 1 To LenB(body)\n'
+'      array(i-1) = AscB(MidB(body, i, 1))\n'
+'    Next\n'
+'    responseBodyToArray=array\n'
+'End Function\n'
;
function loadBinary(url, f, sync) {
    var ff = function(text, error, status) {
        var data;
        if (text != undefined) {
            if (plat.ie) {
                if (responseBodyToArray_vb) {
                    var script = require('./dom').doc(["script", {type: "text/vbscript"}]);
                    script.text = responseBodyToArray_vb; // script.canHaveChildren is false in IE
                    responseBodyToArray_vb = undefined;
                    document.body.appendChild(script);
                }
                data = window.responseBodyToArray(text).toArray();
            } else {
                data = Array(text.length);
                for (var i=0; i<text.length; i++)
                   data[i] = text.charCodeAt(i) & 0xff; // extract from UNICODE Private Area: 0xF700-0xF7ff
            }
        }
        return f(data,error,status);
    };
    return loadContent(url, null, ff , plat.ie ? 'responseBody' : 'responseText', true, sync);
}
function loadText(url, f, sync) {
    return loadContent(url, null, f, void 0, false, sync);
}
function _interpJSON(f, text, error, status) {
    var json;
    if (text)
        try { json = JSON.parse(text); }
        catch(err) { if (error == void 0) error = err.message; }
    if (json && 'status' in json && json.status != 'ok') { // allow the json to return an error in a consistent way
        error = json.status;
        json = undefined;
    }
    return f(json, error, status);
}
function interpJSON(f) {
    return function(text, error, status) {
        return _interpJSON(f, text, error, status);
    };
}
function loadJSON(url, f, sync) {
    return loadContent(url, null, interpJSON(f), void 0, false, sync);
}
// For post*: Unlike loadJSON you have to wrap your callback with iterpJSON() if you expect JSON back.
function postForm(url, data, f) {
    // This particular version of IE stupidly won't POST, so use GET instead. Dumb.
    if (plat.ua == "Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1)")
        return loadContent(url + (url.match(/\?/) ? '&' : '?') + makeurlparams(data), null, f);
    return loadContent(url, {data: makeurlparams(data), mimetype:'application/x-www-form-urlencoded; charset=UTF-8'}, f);
}
function postJSON(url, data, f) {
    return loadContent(url, {data: json(data), mimetype:'application/json; charset=UTF-8'}, interpJSON(f));
}
function headURL(url, f, sync) {
    return loadContent(url, void 0, f, void 0, false, sync);
}

function loadSync(async_func, url, want_status) {
    var data,error,status;
    async_func(url, function(_data, _error, _status) {
        data = _data; error = _error; status = _status;
    }, true);
    if (error) throw(error);
    return want_status ? status : data;
}
function loadXMLSync(url) {
    return loadSync(loadXML, url);
}
function loadBinarySync(url) {
    return loadSync(loadBinary, url);
}
function loadTextSync(url) {
    return loadSync(loadText, url);
}
function loadJSONSync(url) {
    return loadSync(loadJSON, url);
}
function headURLSync(url) {
    var data,error,status;
    headURL(url, function(_data, _error, _status) {
        data = _data; error = _error; status = _status;
    }, true);
    if (status != 404 && error) throw(error);
    return status;
}

module.exports = {
    makeurlparams:  makeurlparams,
    makeurl:        makeurl,
    loadXML:        loadXML,
    loadBinary:     loadBinary,
    loadText:       loadText,
    loadJSON:       loadJSON,
    postForm:       postForm,
    postJSON:       postJSON,
    headURL:        headURL,
    interpJSON:     interpJSON,
    loadXMLSync:    loadXMLSync,
    loadBinarySync: loadBinarySync,
    loadTextSync:   loadTextSync,
    loadJSONSync:   loadJSONSync,
    headURLSync:    headURLSync
};
