// Copyright 2005-2006 David Caldwell <david@porkrind.org>

var Cookie = require('./dom').Cookie;
var lib = require('./lib'), GoodRandom=lib.NewlibRandom, merge=lib.merge;
var ajax = require('./ajax'), loadJSON=ajax.loadJSON, makeurl=ajax.makeurl;

function GOTD() {
    this.random = new GoodRandom();
    this.played = [];
}

GOTD.prototype.init = function(gotd, chain) {
    this.game_of_the_day = gotd;
    this.seed_chain = chain;
    this.played = [];
    if (Cookie.list.gotd) {
        var games = Cookie.list.gotd.split(/,/);
        var prev_gotd = games.shift();
        if (prev_gotd == this.game_of_the_day)
            for (var g in games)
                this.played[games[g]] = true;
    }
}
GOTD.prototype.set_played = function(game) {
    this.played[game] = true;
    var games = [];
    for (var g in this.played)
        games.push(g);
    Cookie.set("gotd", [ this.game_of_the_day ].concat(games).join(","), '1d');
}

GOTD.prototype.next_unplayed = function(title) {
    this.chain[title].i++;
    Cookie.set("chain", JSON.stringify(this.chain), '1d');
    return this.random.rand();
}

GOTD.prototype.init_unplayed = function(title, seed_chain, origin) {
    this.random.srand(seed_chain.start);
    this.next_seed_chain = Date.now() + seed_chain.ttl * 1000;
    this.debug = merge(seed_chain, { origin: origin, now: Date.now() });
    this.chain = JSON.parse(Cookie.list.chain || "{}");
    if (!this.chain[title] || this.chain[title].s !== seed_chain.start) {
        this.chain[title] = { s: seed_chain.start, i: 0 };
        Cookie.set("chain", JSON.stringify(this.chain), '1d');
    } else // We're on the same chain as a previous page load, so catch up to where we are in the chain so we don't repeat seeds.
        for (var i=0; i < this.chain[title].i; i++)
            this.random.rand();
}

GOTD.prototype.fetch_unplayed = function(title) {
    if (this.seed_chain) { // Delayed initialization. Can't be done in GOTD() because we don't know the title yet.
        var seed_chain = this.seed_chain;
        delete this.seed_chain;
        return this.init_unplayed(title, seed_chain, "html");
    }
    if (this.next_seed_chain > Date.now())
        return;
    loadJSON(makeurl("play", "seed_chain"), function(seed_chain, error, status) {
        if (!error)
            this.init_unplayed(title, seed_chain, "api");
    }.bind(this));
}

GOTD.prototype.sane_seed = function(seed) {
    seed = parseInt(seed);
    return (isFinite(seed) && seed < 0xffffffff && (seed > 0 || seed == -1)) ? Math.floor(seed) : undefined;
}

GOTD.prototype.pick_seed = function(seed, name) {
    seed = this.sane_seed(seed);
    if (!this.game_of_the_day) return seed != undefined ? seed : Math.floor(Math.random()*0x7fffffff); // only if we are uninitialzed, as when used from score_game.js
    this.fetch_unplayed(name);
    if (seed == undefined)
        seed = !this.played[name] ? this.game_of_the_day : this.next_unplayed(name);
    if (seed == this.game_of_the_day)
        this.set_played(name);
    return seed;
}

GOTD.prototype.start_it = function() { this.start(this.game_of_the_day) }
GOTD.prototype.start = function(game_of_the_day) { /*after including this file, override this function*/ };

module.exports = new GOTD();

