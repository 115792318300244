// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.

var nbsp = "\u00a0";

// From Newlib:
/* Pseudo-random generator based on Minimal Standard by
   Lewis, Goodman, and Miller in 1969.
 
   I[j+1] = a*I[j] (mod m)

   where a = 16807
         m = 2147483647

   Using Schrage's algorithm, a*I[j] (mod m) can be rewritten as:

   note that the seed value of 0 cannot be used in the calculation as
   it results in 0 itself
*/

function NewlibRandom() {
    this.random_seed = 0;
}
NewlibRandom.prototype.srand = function(seed) { this.random_seed = seed; return this; }
NewlibRandom.prototype.seed = function() { return this.random_seed; }
NewlibRandom.prototype.rand = function() {
    return this.random_seed = (this.random_seed || 0x12345987) * 16807 % 0x7fffffff;
}

var GoodRandom = NewlibRandom; // Maybe switch to Mersenne Twister one day

var __random = new GoodRandom();
function srand(seed) { __random.srand(seed); }
function rand() { return __random.rand(); }
function randseed() { return __random.seed(); }

function chr(code) { return String.fromCharCode(code); }
function asc(chr)  { return chr.charCodeAt(0); }
function isupper(c) { return c >= 65 && c <= 90; };
function islower(c) { return c >= 97 && c <= 122; };
function isdigit(c) { return c >= 48 && c <= 57; };
function ispunct(c) { return c >= 33 && c <= 47 || c >=  58 && c <=  64 ||
                             c >= 91 && c <= 96 || c >= 123 && c <= 126; }
function isalpha(c) { return isupper(c) || islower(c); };
function isalnum(c) { return isalpha(c) || isdigit(c); };
function isgraph(c) { return isalnum(c) || ispunct(c); };
function tolower(c) { return isupper(c) ? c + (97-65) : c; };

// Difference from object.assign: Merges all props not just ownEnumerable
function merge_all_into(to /*, from, [from, ...]*/) {
    for (var a=1; a<arguments.length; a++)
        for (var i in arguments[a])
            to[i] = arguments[a][i];
    return to;
}
// Merges all props together, useful for 'x.prototype = inherit(new x(), new y());'. Standard merge will fail
// in that case, because most things in the new object are in the prototype and so aren't 'own' or 'enumerable'.
function inherit(/*a, [b, ...]*/)
{
    Array.prototype.unshift.call(arguments,{});
    return merge_all_into.apply(null, arguments);
}

// For general case merges, use the browser's built in stuff for speed.
var merge_into = Object.assign;

function merge(/*a, [b, ...]*/)
{
    Array.prototype.unshift.call(arguments,{});
    return merge_into.apply(null, arguments);
}

var have_filter = [].propertyIsEnumerable && [].propertyIsEnumerable('filter') == false && typeof [].filter == "function";
function filter(f,a) {
    if (a.constructor == Array && have_filter)
        return a.filter(f);

    var b = [];
    for (var i in a)
        if (f(a[i],i,a))
            b.push(a[i]);
    return b;
}
function filter_obj(f,a) {
    var b = [];
    for (var i in a)
        if (f(a[i], i, a))
            b[i] = a[i];
    return b;
}
var have_map = [].propertyIsEnumerable && [].propertyIsEnumerable('map') == false && typeof [].map == "function";
function map(f,a) {
    if (a.constructor == Array && have_map)
        return a.map(f);

    var b = [];
    // This complication is only for IE, which doesn't enumerate array indices when the value at that index is
    // undefined. Otherwise we could always just do "for (var i in a)"
    if (a && a.constructor == Array)
        for (var i=0; i<a.length; i++)
            b.push(f(a[i],i,a));
    else
        for (var i in a)
            b.push(f(a[i],i,a));
    return b;
}
function map_obj(f,a) {
    var b = {};
    for (var i in a) {
        var o = {};
        o[i] = a[i];
        merge_into(b, f(i,o,a));
    }
    return b;
}
function add(a) {
    var sum=0;
    for (var i=0; i<a.length; i++)
        sum += a[i];
    return sum;
}

function rot13(s) {
    var sa = s.split(""), out="";
    for (var c in sa) {
        var base = sa[c].toLowerCase() == sa[c] ? "a".charCodeAt(0) : "A".charCodeAt(0);
        out += (sa[c].toLowerCase()<'a' || sa[c].toLowerCase>'z') ? sa[c] :
            String.fromCharCode((sa[c].charCodeAt(0) - base + 13) % 26+base);
    }
    return out;
}

function shuffle_perfect(array) { // Knuth
    for (var a=1; a<array.length; a++) {
        var b = rand() % (a+1);
        var tmp = array[a];
        array[a] = array[b];
        array[b] = tmp;
    }
    return array;
}

module.exports = {
    nbsp: nbsp,
    NewlibRandom: NewlibRandom,
    srand: srand,
    rand: rand,
    randseed: randseed,
    chr: chr,
    asc: asc,
    isupper: isupper,
    islower: islower,
    isdigit: isdigit,
    ispunct: ispunct,
    isalpha: isalpha,
    isalnum: isalnum,
    isgraph: isgraph,
    tolower: tolower,
    merge_into: merge_into,
    merge: merge,
    inherit: inherit,
    filter: filter,
    filter_obj: filter_obj,
    map: map,
    map_obj: map_obj,
    add: add,
    rot13: rot13,
    shuffle_perfect: shuffle_perfect
};
