// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.

var lib = require('./lib'), map=lib.map;

if (!window.JSON) {
    window.JSON = {};
}
if (typeof JSON.parse !== 'function') {
    JSON.parse = function(text, reviver) {
        if (reviver) throw("we don't handle reviver yet.");
        return eval("____="+text);
    }
}
if (typeof JSON.stringify !== 'function') {
    JSON.stringify = function(s/*value*/, replacer, space, _indent, _key) {
    var rep = "";
    if (s===null || s==undefined) return rep += "null";
    switch (typeof(s)) {
        case "function":
        case "number": rep += isFinite(s) ? s.toString() : "null"; break;
        case "string": rep += '"'
                           + s.replace(/[\\"\u0000-\u001f]/g, // RFC 4627 section 2.5
                                       function(match) { var c = match.charCodeAt(0);
                                                         return   c == 0x22 ? '\\"'
                                                                : c == 0x5C ? "\\\\"
                                                                : c == 0x2F ? "\\/"
                                                                : c == 0x08 ? "\\b"
                                                                : c == 0x0C ? "\\f"
                                                                : c == 0x0A ? "\\n"
                                                                : c == 0x0D ? "\\r"
                                                                : c == 0x09 ? "\\t"
                                                                : "\\u00"+encodeURIComponent(match).replace("%","");
                                                       })
                           + '"'; break;
        case "boolean": rep += s ? "true" : "false"; break;
        case "object":
            _indent = _indent || 0;
            var x = function(str, num) { var s = ""; for (var i=0; i<num; i++) s+=str; return s; }; // Perl's "x" operator.
            var indent    = typeof(space) == "string" ? x(space, _indent)   : x(" ",  _indent    * space);
            var newindent = typeof(space) == "string" ? x(space, _indent+1) : x(" ", (_indent+1) * space);
            var wrap = function(b,a,e) {
                if (!space) return b+a.join(",")+e;
                //if (a.join(", ").length < 80) return b+" "+a.join(", ")+" "+e; // I like this but it's not how JSON.stringify is specced.
                return b+"\n"+newindent+a.join(",\n"+newindent)+"\n"+indent+e;
            }
            var rmap = function(f, a) { // map & handle replacer parameter
                var o = [];
                if (replacer && replacer.constructor == Array) {
                    for (var i=0; i<replacer.length; i++)
                        if (replacer[i] in a)
                            o.push(f(a[replacer[i]], replacer[i]));
                } else if (typeof(replacer) == "function")
                    return map(function(v,k) { return f(replacer(k,v), k) }, a);
                else
                    return map(function(v,k) { return f(v, k) }, a);
                return o;
            }
            if (typeof(s.toJSON) == "function") rep += JSON.stringify(s.toJSON.call(s,_key), replacer, space, _indent);
            else if (s.constructor == Array)    rep += wrap('[',rmap(function(v,k) { return                                   JSON.stringify(v, replacer, space, _indent+1, k) }, s),']');
            else                                rep += wrap('{',rmap(function(v,k) { return '"'+k+'":' + (space ? " " : "") + JSON.stringify(v, replacer, space, _indent+1, k) }, s),'}');
            break;
        default: rep += '???';
    }
    return rep;
  }
}

function json(s, name, indent) {
    return (name ? name+" = " : "") +
            JSON.stringify(s,
                           function (k, v) {
                               if (v === window) return "<window>";
                               if (v === document) return "<document>";
                               if (typeof(v) == "number") return isFinite(v) ? v : v.toString(); // Capture NaNs and Infinity
                               if (typeof(v) == "object" && v && "nodeType" in v) return "DOM("+html_from_dom(v)+")";
                               else return v;
                           }, indent || 4);
}

function html_from_dom(el, _indent) {
    var nl = _indent == void 0 ? "" : "\n";
    if (el.nodeType == el.TEXT_NODE)
        return el.data;
    var attributes="";
    if (el.attributes)
        for (var i=0; i<el.attributes.length; i++)
            attributes += " "+el.attributes.item(i).name + "=\"" + el.attributes.item(i).value + "\"";
    var new_indent = _indent != void 0 && (_indent + "  ");
    var txt_indent = _indent == void 0 ? '' : _indent;
    return txt_indent  + "<"+el.tagName+attributes+">" + (!el.childNodes.length ? '' : nl
                       + map(function(e) { return html_from_dom(e, new_indent) }, el.childNodes).join("")
         + txt_indent) + "</"+el.tagName+">" + nl
}

module.exports = json;
json.html_from_dom = html_from_dom;
