// Copyright 2011 David Caldwell <david@greenfelt.net>, Jim Radford <jim@greenfelt.net>

Function.prototype.extend = function(constructor,prototype) {
    var proto_desc = {};
    for (var p in prototype)
        proto_desc[p] = Object.getOwnPropertyDescriptor(prototype, p);

    proto_desc.constructor = { configurable: true, enumerable: false, writable: true, value: constructor };

    constructor.prototype = Object.create(this.prototype, proto_desc);

    return constructor;
}

// Backwards compatibility for non ECMA-262-5 browsers:
try {
    Object.getOwnPropertyDescriptor({x:1},"x")
} catch(e) {
    Object.getOwnPropertyDescriptor = function(obj, name) {
        return { configurable: true, enumerable: true, writable: true, value: obj[name] };
    }
}

if (!Object.create)
    Object.create = function (o, desc) {
        function empty() {}
        empty.prototype = o;
        var n = new empty();
        for (var d in desc)
            n[d] = desc[d].value;
        return n;
    }
